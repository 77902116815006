.heading{
    padding: 60px 0 30px;
    font-style: italic;
    text-align: center;
}
h2.heading {
    color: #333;
    font-size: 30px;
    font-weight: 600;
}

@media only screen and (max-width: 768px) {
    h2.heading {
        font-size: 25px;
    }
}