.blog-card {
    margin: 0 15px;
    padding: 10px 20px 10px 10px;
}
.blog-card img{
    width: 100%;
    height: 350px;
    object-fit: cover
}

.blog-card h5 {
    color: #666;
    font-size: 13px;
    font-weight: 500;
    padding-top: 5px;
}

.blog-card h4 {
    color: #333;
    font-size: 20px;
    font-weight: 500;
    padding: 10px 0;
    line-height: 25px;
}

.blog-card p {
    color: #222;
    font-size: 16px;
    font-weight: 500;
}


.slick-prev {
    left: 90px !important;
    z-index: 10;
}
.slick-next {
    right: 105px !important;
    z-index: 10;
}
.blog-section .slick-prev, .blog-section .slick-next {
    top: 110% !important;
    transform: translate(0, -50%);
}



@media only screen and (max-width: 768px) {
    .blog-card {
        margin: 0 10px;
        padding: 5px 5px 10px 5px;
    }
}


@media only screen and (max-width: 577px) {
    .slick-prev {
        left: 5px !important;
        z-index: 10;
    }
    .slick-next {
        right: 15px !important;
        z-index: 10;
    }
    .blog-section .slick-prev, .blog-section .slick-next {
        top: 108% !important;
        transform: translate(0, -50%);
    }

}