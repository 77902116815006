.login-section{
    padding: 10px;
    max-width: 40%;
    width: 100%;
}

.reg-section{
    padding: 10px;
    max-width: 60%;
    width: 100%;
}

.tab-buttons .btn:not(.active) {
    background-color: #ccc;
}

.tab-buttons {
    padding-left: 10px;
    border-bottom: 1px solid #A7BE30;
    box-sizing: content-box;
}
.tab-buttons .btn {
    border-bottom: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.for-company label input{
    display: none;
}

.image-container img{
    max-width: 220px;
    width: 100%;
}

@media only screen and (max-width: 800px) {
    .login-or-registration{
        flex-direction: column;
    }
    .login-section {
        max-width: 100%;
    }
    .reg-section {
        max-width: 100%;
    }
}
