.detail-section {
    margin-top: 100px;
}

.detail-section img {
    max-width: 100%;
    width: 100%;
    height: 600px;
    object-fit: cover;
}

.detail-section h3 {
    color: #333;
    font-size: 25px;
    font-weight: 500;
}

.detail-section p {
    color: #555;
    font-size: 17px;
    font-weight: 500;
    padding: 0 0 10px 0;
}


.file {
    font-size: 20px;
    padding: 10px 0;
    border-bottom: 1px solid #ddd;
    max-width: 220px;
    width: 100%;
}

.file a {
    font-size: 20px;
    font-weight: 500;
}
.file a svg {
    font-size: 25px;
    margin-right: 10px;
}

.pdf a svg path {
    stroke: #f00 !important;
}

.word a svg path {
    stroke: #0261ce !important;
}

.excel a svg path {
    stroke: #52961d !important;
}

