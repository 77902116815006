header{
    width: 100%;
    background: #A7BE30;
    padding: 10px;

    -webkit-box-shadow: 0 12px 26px -3px rgba(100,100,100,0.67);
    -moz-box-shadow: 0 12px 26px -3px rgba(100,100,100,0.67);
    box-shadow: 0 12px 26px -3px rgba(100,100,100,0.67);
    z-index: 999;

    position: fixed;
    top: 0;
}

.wrapper {
    min-height: 70vh;
}

.nav{
    display: flex;
    column-gap: 15px;
    z-index: 999999;
}

.locals{
    justify-content: center;
    align-items: center;
    column-gap: 10px;
    margin-left: 0;
}
.locals img{
    display: inline-block;
    width: 26px;
    height: 25px;
    cursor: pointer;
}


.burger-button{
    color: #fff;
    font-size: 30px;
    display: flex;
    align-items: center;
    cursor: pointer;
}


.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999999;
    /*backdrop-filter: blur(5px);*/
}

.modal-content {
    padding: 40px 20px;
    border-radius: 10px;
    max-width: 400px;
    width: 100%;
    justify-content: center;
    background: rgba(0, 0, 0, 0.6);
    -webkit-box-shadow: 2px 4px 30px -4px rgba(0,0,0,0.67);
    -moz-box-shadow: 2px 4px 30px -4px rgba(0,0,0,0.67);
    box-shadow: 2px 4px 30px -4px rgba(0,0,0,0.67);
    backdrop-filter: blur(10px);
}

.modal-menu{
    flex-direction: column;
    row-gap: 15px;
}
.modal-menu a.active {
    color: #A7BE30;
}
.modal-menu a{
    color: #fff;
    font-size: 20px;
    font-weight: 500;
}

.close-button {
    position: absolute;
    top: 10px;
    right: 50px;
    cursor: pointer;
}

.close-button span {
    font-size: 50px;
    color: #fff;
}

#root.modal-open {
    overflow: hidden;
    position: fixed;
    width: 100%;
    height: 100%;
}

/* Footer Start */
footer {
    background: #A7BE30;
    padding: 15px 0;
}

.footer-under-text {
    width: 300px;
    margin-top: 15px;
}
.footer-under-text a {
    color: #333;
    font-size: 15px;
    font-weight: 500;
}
.footer-section{
    display: flex;
    justify-content: space-between;
}


.site-map h4{
    color: #333;
    font-size: 20px;
    font-weight: 500;
}

.site-map-links{
    display: flex;
    flex-direction: column;
    margin-top: 15px;
}
.site-map-links a{
    color: #333;
    font-size: 16px;
    font-weight: 500;
    line-height: 25px;
}


.footer-social-box h4{
    color: #333;
    font-size: 20px;
    font-weight: 500;
}

.social-links{
    column-gap: 10px;
    margin-top: 15px;
}

.social-links svg{
    font-size: 32px;
    color: #333;
    transition: 0.3s;
}
.social-links svg:hover{
    color: #666;
}
img.selected {
    outline: 2px solid #fff;
    border-radius: 50%;
}
/* Footer End */

.alert-container {
    position: fixed;
    max-width: 1200px;
    width: 100%;
    left: 50%;
    top: 100px;
    transform: translateX(-50%);
    display: flex;
    flex-wrap: wrap;
    row-gap: 10px;
    z-index: 3000;
}

.alert-message {
    border: 1px solid;
    border-radius: 5px;
    padding: 10px;
    text-align: center;
    width: 100%;
}

.error {
    color: #ffffff;
    border-color: red;
    background: rgba(224, 4, 4, 0.9);
}

.success {
    color: #ffffff;
    border-color: green;
    background: rgba(2, 124, 2, 0.9);
}
.account-actions {
    display: flex;
    align-items: center;
}
.account-button {
    color: #ffffff;
    font-size: 30px;
}

@media only screen and (max-width: 768px) {
    .footer-section{
        display: flex;
        flex-direction: column;
        row-gap: 10px;
    }
}