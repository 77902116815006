.app-card{
    padding: 0 15px;
}


.app-card img{
    display: block;
    width: 100%;
    height: 250px;
    object-fit: cover;
    border-radius: 5px;
}
.app-card h5{
    color: #555;
    font-size: 15px;
    font-weight: 500;
    padding-top: 5px;
}
.app-card h4{
    color: #333;
    font-size: 20px;
    font-weight: 500;
    padding: 8px 0;
}
.app-card p{
    color: #333;
    font-size: 16px;
    font-weight: 500;
}

.slick-prev {
    left: 90px !important;
    z-index: 10;
}
.slick-next {
    right: 105px !important;
    z-index: 10;
}
.application-section .slick-prev, .application-section .slick-next {
    top: 108% !important;
    transform: translate(0, -50%);
}


@media only screen and (max-width: 768px) {
    .app-card {
        padding: 10px ;
    }
}

@media only screen and (max-width: 577px) {
    .slick-prev {
        left: 5px !important;
        z-index: 10;
    }
    .slick-next {
        right: 15px !important;
        z-index: 10;
    }
    .application-section .slick-prev, .application-section .slick-next {
        top: 105% !important;
        transform: translate(0, -50%);
    }
}