.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}
.pagination a {
    cursor: pointer;
    padding: 10px;
}
.pagination a.active {
    background-color: #007bff;
    color: white;
}

.gallery-list .heading{
    margin-top: 50px;
}

.galley-card{
    padding: 0 15px;
    text-align: center;
}

.galley-card h5{
    color: #333;
    font-size: 15px;
    font-weight: 600;
    padding-top: 5px;
}


/* Modal Start ***** */
.gallery-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
}

.gallery-modal-content {
    background-color: white;
    border-radius: 10px;
    padding: 10px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.gallery-modal-image {
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 80vh;
    margin-bottom: 10px;
}

.gallery-modal-close {
    background-color: #000;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: 0.3s;
}
.gallery-modal-close:hover {
    background-color: #333;
}
/* Modal End ***** */



@media only screen and (max-width: 768px) {
    .galley-card {
        padding: 10px;
    }
    .galley-card h5 {
        padding-top: 5px;
    }
}




