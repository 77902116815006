.form-box{
    display: flex;
    column-gap: 30px;
    width: 100%;
}
.feedback-upload{
    max-width: 500%;
    width: 100%;
    height: 500px;
    border: 1px solid #c2c2c2;
    border-radius: 5px;
}
.feedback-upload img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.feedback-input{
    max-width: 50%;
    width: 100%;
    display: flex;
    flex-direction: column;
}


.feedback-upload label {
    cursor: pointer;
}
.feedback-upload input[type="file"] {
    display: none;
}

.feedback-section-wrapper {
    background: #ffffff;
    padding: 20px 10px;
}

.feedback-input label{
    color: #333;
    font-size: 18px;
    font-weight: 500;
}
.feedback-input input{
    max-width: 100%;
    padding: 15px;
    border-radius: 5px;
    outline: none;
    border: 1px solid #555;
    margin: 10px 0;
}

.feedback-input textarea{
    max-width: 100%;
    height: 220px;
    padding: 16px;
    margin: 15px 0;
    outline: none;
}

.feedback-input input{
    max-width: 100%;
}

.feedback-input textarea{
    max-width: 100%;
    min-height: 150px;
}
.feedback-input textarea, .feedback-input input {
    padding: 16px;
    outline: none;
    border: 1px solid #c2c2c2;
    border-radius: 5px;
}

.feedback-input textarea::placeholder, .feedback-input input::placeholder {
    color: #c2c2c2;
}

.feedback-input textarea:focus, .feedback-input input:focus {
    border-color: #8f8f8f;
}


@media only screen and (max-width: 768px) {
    .form-box{
        display: flex;
        flex-direction: column;
        row-gap: 30px;
    }
    .feedback-upload {
        height: 350px;
    }

    .feedback-input{
        max-width: 100%;
    }

}




