.about-section {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    padding: 20px 50px;
    position: relative;
}

.about-section-text{
    width: 100%;
    padding: 20px;
    margin: 90px 0;
    max-width: 550px;
    position: relative;
}

.about-section-detail{
    display: flex;
}

.about-section-text p{
    font-size: 17px;
    font-weight: 500;
    color: white;
    letter-spacing: 1.1px;
}

.about-detail-text{
    padding: 0 30px 0 15px;
}
.about-detail-text p{
    color: #333;
    font-size: 18px;
    font-weight: 500;
    line-height: 25px;
}

.about-section-detail img{
   width: 500px;
}
.about-section-decor {
    width: 50%;
    height: 100%;
    top: 0;
    left: 0;
    background: #22222299;
    position: absolute;
    backdrop-filter: blur(30px);
    z-index: 0;
}

.about-pdf{
    margin-top: 30px;
    border: 1px solid #ccc;
    max-width: 220px;
    width: 100%;
    border-radius: 5px;

    -webkit-box-shadow: 1px 2px 6px 2px rgba(34, 60, 80, 0.15);
    -moz-box-shadow: 1px 2px 6px 2px rgba(34, 60, 80, 0.15);
    box-shadow: 1px 2px 6px 2px rgba(34, 60, 80, 0.15);
}

.about-detail-text .file a {
    font-size: 25px;
    font-weight: 500;
    display: flex;
    align-items: center;
    padding: 5px;
}
.about-detail-text .file a svg {
    font-size: 35px;
    margin-right: 20px;
}


@media only screen and (max-width: 991px) {
    .about-section-detail{
        display: flex;
        flex-direction: column;
    }

    .about-section-detail img{
        width: 100%;
        object-fit: contain;
    }

    .about-detail-text{
        margin-top: 20px;
        padding: 10px;
    }
}
@media only screen and (max-width: 768px) {
    .about-section {
        padding: 20px 20px;
    }

    .about-section-text{
        backdrop-filter: blur(80px);
        background: rgba(0,0,0,0.1);
        padding: 15px;
        margin: 20px 0;
    }

    .about-section-detail{
        display: flex;
        flex-direction: column;
    }
}

