.video-box {
    position: relative;
    z-index: 10;
}

.video-section {
    width: 100%;
    height: 800px;
}

.video-section video {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.hero-section {
    position: absolute;
    background: #222222bf;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    top: 0;
    height: 100%;
    width: 100%;
}
.hero-box {
    max-width: 500px;
    width: 100%;
    padding: 20px;
}
.hero-text {
    color: #fff;
    font-size: 24px;
    font-weight: 400;
}

.hero-box h1{
    color: #A7BE30;
    font-size: 100px;
    font-weight: 600;
}

.hero-bg-white{
    background: #ffffff;
    height: 10px;
    width: 100%;
    border-radius: 5px;
    margin: 10px 0;
}

.login-btn{
    position: absolute;
    top: 15%;
    right: 5%;
    background: #A7BE30;
    border-radius: 5px;
    padding: 7px 20px;
    cursor: pointer;
    -webkit-box-shadow: 6px 4px 17px -3px rgba(0,0,0,0.67);
    -moz-box-shadow: 6px 4px 17px -3px rgba(0,0,0,0.67);
    box-shadow: 6px 4px 17px -3px rgba(0,0,0,0.67);
}

.login-btn a{
    color: #fff;
    font-size: 17px;
    font-weight: 500;
    letter-spacing: 1.1px;
}




@media only screen and (max-width: 768px) {
    .hero-section {
        max-width: 100%;
        width: 100%;
    }
    .login-btn{
        position: absolute;
        top: 12%;
        right: 5%;
    }

}






