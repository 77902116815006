.service-box{
    margin: 0 auto;
    align-items: center;

}

.service-box div{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
    text-align: center;
}

.service-cart{
    max-width: 100%;
    width: 100%;
    background: #fff;
    border-radius: 10px;
    -webkit-box-shadow: 9px 10px 17px -14px rgba(0,0,0,0.67);
    -moz-box-shadow: 9px 10px 17px -14px rgba(0,0,0,0.67);
    box-shadow: 9px 10px 17px -14px rgba(0,0,0,0.67);
    transition: 0.3s;
}
.service-cart:hover{
    -webkit-box-shadow: 15px 15px 27px -13px rgba(0,0,0,0.77);
    -moz-box-shadow: 15px 15px 27px -13px rgba(0,0,0,0.77);
    box-shadow: 15px 15px 27px -13px rgba(0,0,0,0.77);
}

.service-box-img {
    display: inline-block;
    width: 100%;
}
.service-box-img img{
    display: inline-block;
    width: 100px;
    height: 100px;
    object-fit: contain;
}
.service-box p{
    color: #333;
    font-size: 16px;
    font-weight: 500;
}

